<template>
  <div>
    <Chatbot></Chatbot>
  </div>
</template>

<script>
import {
  default as Chatbot
} from '@/components/widgets/Chatbot';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import {
  default as router
} from '@/router';

export default {
  name: 'ChatBotIframe',
  computed: {
    ...mapGetters({})
  },
  props: {},
  components: {
    Chatbot
  },
  data () {
    return {};
  },
  created: function () {},
  mounted: function () {},
  methods: {},
  watch: {}
};

</script>

<style type="text/css">
.table-row-directory {
  border-bottom: solid 1px #e6e6e6;
}

.table-row-directory:hover {
  background: #ddfffd;
}

</style>

